body {
  margin: 0;
  padding: 0;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px){
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
}